@import 'src/styles/variables';
@import 'src/styles/mixins';

.input {
  @include input;
  text-transform: uppercase;

  &::before {
    content: attr(data-placeholder);
    color: $black40;
  }
}

.inputPlaceholderColor {
  color: $black40;
}

// Safari Mobile
.inputFilled {
  color: $black100;
}

.input::-webkit-date-and-time-value {
  text-align: left !important;
}
